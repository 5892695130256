import React from "react"
import { ErrorBoundary } from "ErrorBoundary"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./styles/index.scss"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
