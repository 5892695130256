import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import "@fontsource/roboto"
import SiteLayout from "./components/layout/SiteLayout"
import DonateOptions from "./pages/DonateOptions"
import Events from "./pages/Events"
import Home from "./pages/Home"

const App = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route element={<SiteLayout />}>
          <Route element={<Navigate replace to="/home" />} path="/" />
          <Route element={<Home />} path="/home" />
          <Route element={<Events />} path="/events" />
          <Route element={<DonateOptions />} path="/options" />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
