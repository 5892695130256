import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import Eventicon from "@mui/icons-material/Event"
import HomeIcon from "@mui/icons-material/Home"
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism"

import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Avatar,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material"
import logo from "assets/images/logo.png"
import LogoExtend from "./LogoExtend"

const style1 = (theme) => ({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
})

const style2 = (theme) => ({
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
})

const iconStyle = {
  justifyContent: "center",
  flexDirection: "row",
  display: "flex",
}
const iconColor = { color: "#fff" }
const logoContainer = {
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  gap: 1,
}
const logoStyle = {
  fontSize: "28px",
  fontWeight: "bold",
}

const toolBarStyle = {
  padding: "0 8px",
  margin: "0 15px",
  borderRadius: "8px",
  //  justifyContent: "space-between",
  alignItems: "center",
  background: "linear-gradient(90deg,  #4A90E2 40%, #d11947 100%)",
}

const navProp = ({ isActive, isPending, isTransitioning }) => {
  return {
    textDecoration: "none",
    fontWeight: isActive ? "bold" : "",
    color: isPending ? "red" : "black",
    opacity: isActive ? 1 : 0.5,
    viewTransitionName: isTransitioning ? "slide" : "",
    padding: "0 10px",
  }
}
const Navbar = (): JSX.Element => {
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <Stack>
      <AppBar color="transparent" elevation={0} position="static">
        <Toolbar style={toolBarStyle}>
          <Stack sx={logoContainer}>
            <IconButton onClick={() => navigate("/home")}>
              <Avatar alt="AHLA" src={logo} sx={{ width: 60, height: 60 }} />
            </IconButton>
            <Stack gap={1} sx={{ flexDirection: "row" }}>
              <Stack
                gap={1}
                sx={{ flexDirection: "row", alignItems: "center" }}
              >
                <Typography style={logoStyle}>A</Typography>
                <Typography style={logoStyle}>H</Typography>
                <Stack
                  sx={{
                    background: "#fff",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={logoStyle}
                    sx={{
                      color: "green",
                      fontWeight: "bold",
                    }}
                  >
                    L
                  </Typography>
                </Stack>

                <Typography style={logoStyle}>A</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Box sx={style1(theme)}>
            <NavLink style={navProp} to="/home">
              <Box sx={iconStyle}>
                <HomeIcon sx={iconColor} />
                <Typography sx={iconColor}>Home</Typography>
              </Box>
            </NavLink>
            <NavLink style={navProp} to="/options">
              <Box sx={iconStyle}>
                <VolunteerActivismIcon sx={iconColor} />
                <Typography sx={iconColor}>Donate</Typography>
              </Box>
            </NavLink>
            <NavLink style={navProp} to="/events">
              <Box sx={iconStyle}>
                <Eventicon sx={iconColor} />
                <Typography sx={iconColor}>Events</Typography>
              </Box>
            </NavLink>
          </Box>
          <Box sx={style2(theme)}>
            <Box sx={{ position: "absolute", right: "10px", bottom: 0 }}>
              <LogoExtend />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Stack>
  )
}
export default Navbar
