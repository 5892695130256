import { Component, ReactNode } from "react"

interface Props {
  readonly children?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    const { hasError } = this.state
    const { hasError: nextHasError } = nextState

    const { children } = this.props
    const { children: nextChildren } = nextProps
    return hasError !== nextHasError || children != nextChildren
  }

  componentDidCatch(error, info) {
    console.log("Error logged:", error, "Info:", info)
  }

  render() {
    const { children } = this.props
    return children
  }
}
